import { format, parseISO } from 'date-fns';

export const getTodayDate = () => new Date().toISOString();

export const getNewDate = (date?: string) =>
  date ? new Date(date) : new Date();

export const toISOShortString = (date: string) => {
  if (!date) return '';
  return format(parseISO(date), 'yyyy-MM-dd');
};

export const toUSLocaleShortString = (date: string) =>
  format(parseISO(date), 'MM/dd/yyyy');

export const toISOString = (date: Date) => date.toISOString();
