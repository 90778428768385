import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { PermissionsType } from '@shared/types/forms';

export enum Page {
  dashboard = 'dashboard',
  permissions = 'permissions',
}

export const useAppLocation = () => {
  const location = useLocation();
  const urlPathname = location.pathname.split('/').slice(1);
  const page = Page[urlPathname[0] as Page];
  const isOrganizationPathname = useMemo(
    () => urlPathname.includes(PermissionsType.Organization),
    [urlPathname],
  );
  return { location, urlPathname, page, isOrganizationPathname };
};

export default useAppLocation;
