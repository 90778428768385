import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';
import { OrganizationAccount } from 'services/ClaimscoreApiService/Organizations/types';

type SetStateFn = (prevValue: OrganizationAccount[]) => OrganizationAccount[];

interface UsersManagementContextProps {
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  activeStep: number;
  setActiveStep: (value: number) => void;
  selectedUsersToInvite: OrganizationAccount[];
  setSelectedUsersToInvite: (value: OrganizationAccount[]) => void;
  inviteUserModalOpen: boolean;
  setInviteUserModalOpen: (value: boolean) => void;
  inviteOrgModalOpen: boolean;
  setInviteOrgModalOpen: (value: boolean) => void;
  updateUsersPayload: OrganizationAccount[];
  setUpdateUsersPayload: (value: OrganizationAccount[] | SetStateFn) => void;
  timeframeDrawerOpen: boolean;
  setTimeframeDrawerOpen: (value: boolean) => void;
}

const UsersManagementContext = createContext<UsersManagementContextProps>({
  searchQuery: '',
  setSearchQuery: () => undefined,
  activeStep: 0,
  setActiveStep: () => undefined,
  selectedUsersToInvite: [],
  setSelectedUsersToInvite: () => undefined,
  inviteUserModalOpen: false,
  setInviteUserModalOpen: () => undefined,
  inviteOrgModalOpen: false,
  setInviteOrgModalOpen: () => undefined,
  updateUsersPayload: [],
  setUpdateUsersPayload: () => undefined,
  timeframeDrawerOpen: false,
  setTimeframeDrawerOpen: () => undefined,
});

export function UsersManagementProvider({ children }: PropsWithChildren) {
  const [searchQuery, setSearchQuery] = useState('');
  const [activeStep, setActiveStep] = useState(0);

  const [inviteUserModalOpen, setInviteUserModalOpen] =
    useState<boolean>(false);
  const [inviteOrgModalOpen, setInviteOrgModalOpen] = useState<boolean>(false);

  const [selectedUsersToInvite, setSelectedUsersToInvite] = useState<
    OrganizationAccount[]
  >([]);

  const [updateUsersPayload, setUpdateUsersPayload] = useState<
    OrganizationAccount[]
  >([]);

  const [timeframeDrawerOpen, setTimeframeDrawerOpen] =
    useState<boolean>(false);

  const contextValues = useMemo(
    () => ({
      activeStep,
      setActiveStep,
      searchQuery,
      setSearchQuery,
      selectedUsersToInvite,
      setSelectedUsersToInvite,
      inviteUserModalOpen,
      setInviteUserModalOpen,
      inviteOrgModalOpen,
      setInviteOrgModalOpen,
      updateUsersPayload,
      setUpdateUsersPayload,
      timeframeDrawerOpen,
      setTimeframeDrawerOpen,
    }),
    [
      activeStep,
      searchQuery,
      selectedUsersToInvite,
      inviteUserModalOpen,
      inviteOrgModalOpen,
      updateUsersPayload,
      timeframeDrawerOpen,
    ],
  );

  return (
    <UsersManagementContext.Provider value={contextValues}>
      {children}
    </UsersManagementContext.Provider>
  );
}

export const useUsersManagement = () => useContext(UsersManagementContext);
