import {
  Collapse as CollapseMui,
  CollapseProps as CollapsePropsMui,
} from '@mui/material';
import React from 'react';

export type CollapseProps = Pick<
  CollapsePropsMui,
  'children' | 'in' | 'sx' | 'timeout'
>;

export function Collapse({ ...rest }: CollapseProps) {
  return <CollapseMui timeout={0} {...rest} />;
}
