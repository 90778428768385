import {
  ColorGreen600,
  ColorNeutral300,
  ColorRed600,
  ColorYellow600,
} from '../../design-system/colors';
import { styled } from '../../utils/styled';
import { Box } from '../Box';
import { Typography } from '../Typography';

export const StyledBox = styled(Box)`
  gap: 2px;
  align-items: flex-start;
  border-radius: ${({ theme }) => theme.spacing(0)};
  background-color: ${({ theme }) => theme.palette.neutral[200]};
  width: 100%;
  overflow: hidden;
`;

const colorList = [ColorGreen600, ColorYellow600, ColorRed600, ColorNeutral300];

export const FilledBar = styled(Box)<{
  index: number;
  value: number;
}>`
  align-items: flex-start;
  background-color: ${({ index, value }) =>
    value === 0 ? colorList[4] : colorList[index]};
  width: ${({ value }) => (value === 0 ? 'unset' : value)}%;
  height: 4px;
`;

export const StyledTypography = styled(Typography)`
  flex: 1;
  text-align: center;
  &:first-of-type {
    text-align: start;
  }
  &:last-of-type {
    text-align: end;
  }
`;
