import {
  Divider as DividerMui,
  DividerProps as DividerPropsMui,
} from '@mui/material';
import React from 'react';
import { ColorNeutral200, ColorNeutral800 } from '../../design-system/colors';
import { styled } from '../../utils/styled';

const StyledDivider = styled(DividerMui, {
  shouldForwardProp: (prop: string) =>
    !['light', 'fullWidth', 'noMargin'].includes(prop),
})<{ light?: boolean; fullWidth?: boolean; noMargin?: boolean }>`
  margin: ${({ noMargin }) => (noMargin ? 'unset' : '10px 0')};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'unset')};
  border-color: ${({ light }) => (light ? ColorNeutral200 : ColorNeutral800)};
`;
export interface DividerProps
  extends Pick<DividerPropsMui, 'sx' | 'orientation' | 'flexItem' | 'variant'> {
  readonly noMargin?: boolean;
  readonly light?: boolean;
  readonly fullWidth?: boolean;
}

export function Divider({
  light = true,
  fullWidth,
  noMargin,
  ...rest
}: DividerProps) {
  return (
    <StyledDivider
      fullWidth={fullWidth}
      light={light}
      noMargin={noMargin}
      {...rest}
    />
  );
}
