import {
  ColorBlue200,
  ColorGreen200,
  ColorOrange200,
  ColorViolet200,
  ColorYellow200,
} from '../../design-system/colors';

const supportedColors = [
  ColorGreen200,
  ColorBlue200,
  ColorOrange200,
  ColorViolet200,
  ColorYellow200,
];

export const getPersistedColor = (): string => {
  const color = window.localStorage.getItem('avatar');
  if (!color) {
    const colorIndex = Math.floor(Math.random() * supportedColors.length);
    const newColor = supportedColors[colorIndex];
    window.localStorage.setItem('avatar', newColor);
    return newColor;
  }
  return color;
};

export const getNameInitials = (name: string): string => {
  const nameParts = name
    .split(' ')
    .map((part: string) => part[0])
    .join('')
    .toLowerCase();

  return nameParts.substring(0, 2);
};
