import React from 'react';
import { SideNavbar as SideNavbarUI } from 'ui';
import { MainSideNavbarContent } from './MainSideNavbarContent';

export interface SideNavbarProps {
  readonly open?: boolean;
  readonly onClose?: () => void;
  readonly onMouseOver?: () => void;
}

export function SideNavbar({ open, onClose, onMouseOver }: SideNavbarProps) {
  return (
    <SideNavbarUI onClose={onClose} onMouseOver={onMouseOver} open={open}>
      <MainSideNavbarContent onClose={onClose} open={open} />
    </SideNavbarUI>
  );
}
