import React, { lazy } from 'react';
import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';
import { Flags, useFlag } from 'ui';
import { ProtectedRoute } from '@components/ProtectedRoute';
import { CaseDashboardProvider } from '@pages/CaseDashboard/CaseDashboardContext';
import { UsersManagementProvider } from '@pages/UsersManagement/UsersManagementContext';
import { PrivateLayout } from '../../../layouts/PrivateLayout';

const Login = lazy(() => import('../../../pages/Login/Login'));

const CaseDashboard = lazy(
  () => import('../../../pages/CaseDashboard/CaseDashboard'),
);

const ClaimForm = lazy(() => import('../../../pages/ClaimForm'));

const UsersManagement = lazy(() => import('../../../pages/UsersManagement'));

export const useAppRoutes = () => {
  const isAccessAndRoleManagementEnabled = useFlag(
    Flags.accessAndRoleManagement,
  );

  const publicRoutes: RouteObject[] = [{ path: '/login', element: <Login /> }];

  const privateClaimFormRoute: RouteObject[] = [
    {
      path: '/',
      element: <PrivateLayout />,
      children: [{ path: '/claim-form', element: <ClaimForm /> }],
    },
  ];

  const privateCaseLayoutRoutes: RouteObject[] = [
    {
      path: '/',
      element: <PrivateLayout withNavbar withSidebar />,
      children: [
        {
          path: '',
          element: <Navigate to="/dashboard" />,
        },
        {
          index: true,
          path: 'dashboard',
          element: (
            <CaseDashboardProvider>
              <CaseDashboard />
            </CaseDashboardProvider>
          ),
        },
        {
          path: 'permissions/:tab?',
          element: (
            <ProtectedRoute hasAccess={isAccessAndRoleManagementEnabled}>
              <UsersManagementProvider>
                <UsersManagement />
              </UsersManagementProvider>
            </ProtectedRoute>
          ),
        },
      ],
    },
  ];

  const routes: RouteObject[] = [
    ...publicRoutes,
    ...privateCaseLayoutRoutes,
    ...privateClaimFormRoute,
  ];

  const router = createBrowserRouter(routes);

  return { routes, router };
};

export default useAppRoutes;
