import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ClaimScoreLogoAnimated from './ClaimScoreLogoAnimated.svg?react';

const TimerThreshold = 300;

export function ClaimScoreLoader() {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(true);
    }, TimerThreshold);

    return () => clearTimeout(timer);
  }, []);

  return (
    showLoader && (
      <Box display="flex" justifyContent="center" width="100%">
        <ClaimScoreLogoAnimated />
      </Box>
    )
  );
}
