import {
  Drawer as DrawerMui,
  DrawerProps as DrawerPropsMui,
} from '@mui/material';
import React from 'react';

export type DrawerProps = DrawerPropsMui;

export function Drawer({ children, ...rest }: DrawerProps) {
  return <DrawerMui {...rest}>{children}</DrawerMui>;
}
