export const currencyFormatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  maximumFractionDigits: 2,
  style: 'currency',
});

export const currencyFormatterInteger = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  maximumFractionDigits: 0,
  style: 'currency',
});

export const numberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  style: 'decimal',
});

export const numberFormatterNoDecimals = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
  style: 'decimal',
});

const MILLION = 1_000_000;
const THOUSAND = 1_000;
const HUNDRED = 100;

export const numberFormatterWithUnits = (number: number) => {
  if (number >= MILLION)
    return `${numberFormatterNoDecimals.format(number / MILLION)}M`;
  if (number >= HUNDRED * THOUSAND) {
    return `${numberFormatterNoDecimals.format(number / THOUSAND)}K`;
  }
  return numberFormatterNoDecimals.format(number);
};
