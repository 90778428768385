import React from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  readonly hasAccess: boolean;
  readonly children: React.ReactElement;
}

export function ProtectedRoute({ hasAccess, children }: ProtectedRouteProps) {
  if (!hasAccess) {
    return <Navigate to="/dashboard" />;
  }

  return children;
}
