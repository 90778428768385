import React, { ReactNode } from 'react';
import { Case } from 'services/ClaimscoreApiService/Cases/types';
import { CleanButton } from '../../utils/clean-button';
import { styled } from '../../utils/styled';
import { Button } from '../Button';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

const ListContainer = styled('div')`
  border-radius: ${({ theme }) => theme.borderRadii.outer};
  background: ${({ theme }) => theme.palette.neutral[900]};
`;

const StyledButton = styled(CleanButton)<{ isRightIcon: boolean }>`
  cursor: ${({ isRightIcon }) => (isRightIcon ? 'default' : 'pointer')};
  overflow: hidden;
  :first-of-type {
    border-top-right-radius: ${({ theme }) => theme.borderRadii.outer};
    border-top-left-radius: ${({ theme }) => theme.borderRadii.outer};
  }

  :last-of-type {
    border-bottom-right-radius: ${({ theme }) => theme.borderRadii.outer};
    border-bottom-left-radius: ${({ theme }) => theme.borderRadii.outer};
  }
`;

const ItemWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'hasIcon',
})<{ hasIcon?: boolean }>`
  padding: ${({ theme }) => theme.spacing(2)};
  &:hover {
    background: ${({ theme, hasIcon }) =>
      hasIcon ? undefined : theme.palette.neutral[800]};
  }
`;

export interface CaseDisplayProps {
  readonly cases: (Pick<Case, 'caseID' | 'caseName'> & {
    rightIcon?: ReactNode;
    onClick?: () => void;
  })[];
}

export function CaseDisplay({ cases }: CaseDisplayProps) {
  const renderContent = ({
    caseID,
    caseName,
    rightIcon,
    onClick,
  }: (typeof cases)[0]) => (
    <ItemWrapper direction="row" hasIcon={Boolean(rightIcon)} key={caseID}>
      <Stack direction="column">
        <Typography
          noWrap
          sx={{
            maxWidth: rightIcon ? '156px' : '216px',
            color: 'text.primaryInverted',
            mb: (theme) => theme.spacing(0),
          }}
          title={caseName}
          variant="subtitle"
        >
          {caseName}
        </Typography>
      </Stack>
      {rightIcon ? (
        <Button
          onClick={onClick}
          startIcon={rightIcon}
          sx={{ marginLeft: 'auto' }}
          variant="text"
        />
      ) : null}
    </ItemWrapper>
  );

  return (
    <ListContainer>
      {cases.map(({ caseID, caseName, rightIcon, onClick }) =>
        rightIcon ? (
          renderContent({ caseID, caseName, rightIcon, onClick })
        ) : (
          <StyledButton
            isRightIcon={Boolean(rightIcon)}
            key={caseID}
            onClick={rightIcon ? undefined : onClick}
            type="button"
          >
            {renderContent({ caseID, caseName, rightIcon, onClick })}
          </StyledButton>
        ),
      )}
    </ListContainer>
  );
}
