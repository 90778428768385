import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { FirebaseAuth } from '../FirebaseService/auth';

const baseURL = import.meta.env.VITE_CLAIMSCORE_API_URL;

const getAuthorizationToken = async (): Promise<string> => {
  const authorizationToken = await FirebaseAuth.getAuthToken();

  return `Bearer ${authorizationToken}`;
};

const claimScoreApiServiceClient: AxiosInstance = axios.create({
  baseURL,
});

claimScoreApiServiceClient.interceptors.request.use(
  async (config) => {
    const token = await getAuthorizationToken();

    config.headers ??= {} as AxiosRequestHeaders;
    config.headers.Authorization = `${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default claimScoreApiServiceClient;
