import { Box as BoxMui, BoxProps as BoxPropsMui } from '@mui/material';
import React from 'react';

export type BoxProps = Pick<
  BoxPropsMui,
  | 'children'
  | 'sx'
  | 'onMouseEnter'
  | 'onMouseLeave'
  | 'width'
  | 'height'
  | 'display'
  | 'mb'
  | 'gap'
  | 'flexWrap'
  | 'flexDirection'
  | 'justifyContent'
  | 'flexGrow'
>;

export function Box({ ...rest }: BoxProps) {
  return <BoxMui {...rest} />;
}
