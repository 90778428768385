import { styled } from './styled';

export const CleanButton = styled('button')`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-align: left;
  width: 100%;
`;
