import { CardActions } from '@mui/material';
import React, { forwardRef, ReactNode, Ref } from 'react';
import { CardProps } from '../Card';
import { Divider } from '../Divider';
import { Stack } from '../Stack';
import { Typography } from '../Typography';
import {
  StyledBorder,
  StyledCard,
  StyledCardContent,
  StyledCardHeader,
  StyledTypography,
} from './PageCardContainer.styles';

export interface PageCardContainerProps extends CardProps {
  readonly title: string;
  readonly subheader?: string;
  readonly borderColor?: string;
  readonly Toolbar?: React.JSX.Element;
  readonly cardAction?: ReactNode;
  readonly children?: React.ReactNode;
  readonly noPadding?: boolean;
  readonly width?: string;
  readonly divider?: boolean;
}

const PageCardContainerComponent = forwardRef(
  (
    {
      title,
      subheader,
      cardAction,
      Toolbar,
      children,
      borderColor,
      noPadding,
      divider = true,
      ...rest
    }: PageCardContainerProps,
    ref: Ref<HTMLDivElement>,
  ) => (
    <StyledCard {...rest} ref={ref}>
      <StyledCardHeader
        action={Toolbar}
        title={
          <Stack direction="row">
            {borderColor ? <StyledBorder borderColor={borderColor} /> : null}
            <Stack direction="column" spacing={0}>
              <Typography variant="h5">{title}</Typography>
              {subheader ? (
                <StyledTypography variant="subtitle">
                  {subheader}
                </StyledTypography>
              ) : null}
            </Stack>
          </Stack>
        }
      />
      {divider ? (
        <Divider fullWidth light noMargin orientation="horizontal" />
      ) : null}
      <StyledCardContent noPadding={noPadding}>{children}</StyledCardContent>
      {cardAction ? (
        <StyledCard>
          {divider ? (
            <Divider fullWidth light noMargin orientation="horizontal" />
          ) : null}
          <CardActions disableSpacing>{cardAction}</CardActions>
        </StyledCard>
      ) : null}
    </StyledCard>
  ),
);
export const PageCardContainer = PageCardContainerComponent;
