import {
  SpeedDialIcon as SpeedDialIconMui,
  SpeedDialIconProps as SpeedDialIconPropsMui,
} from '@mui/material';
import React from 'react';

export type SpeedDialIconProps = SpeedDialIconPropsMui;

export function SpeedDialIcon({ ...rest }: SpeedDialIconProps) {
  return <SpeedDialIconMui {...rest} />;
}
