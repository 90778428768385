import React, {
  MouseEventHandler,
  ReactElement,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import {
  ActionButton,
  Button,
  SpeedDialAction,
  Stack,
  styled,
  useBreakpoints,
} from 'ui';
import { LayoutContext } from '@context/LayoutContext';

const DefaultButton = styled(Button)({
  height: '56px',
  minWidth: '56px',
});

export type ToolbarItem = {
  name: string;
  icon: ReactElement;
  onClick: MouseEventHandler;
  showNameOnDesktop?: boolean;
  variant?: 'primary' | 'action' | 'secondary';
  dataTestId?: string;
};

interface PageToolbarPortalProps {
  items: ToolbarItem[];
}

export const PAGE_TOOLBAR_PORTAL_ID = 'header-page-toolbar';

export function PageToolbarPortal() {
  return <div id={PAGE_TOOLBAR_PORTAL_ID} />;
}

export const PageHeaderToolbar = ({ items }: PageToolbarPortalProps) => {
  const [portalEl, setPortalEl] = useState<HTMLElement>();
  const { isLgUp } = useBreakpoints();
  const { isSpeedDialOpen } = useContext(LayoutContext);

  useLayoutEffect(() => {
    const foundPortal = document.getElementById(
      isLgUp ? PAGE_TOOLBAR_PORTAL_ID : 'Utilities-actions',
    );
    if (!foundPortal)
      throw new Error('Portal container for PageHeaderToolbar does not exist');
    setPortalEl(foundPortal);
  }, [isLgUp]);

  const children = useMemo(
    () =>
      isLgUp ? (
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          spacing={2}
        >
          {items.map(
            ({
              name,
              icon,
              onClick,
              showNameOnDesktop,
              variant = 'action',
              dataTestId,
            }) =>
              variant === 'action' ? (
                <ActionButton
                  data-testid={dataTestId}
                  key={name}
                  label={showNameOnDesktop ? name : undefined}
                  onClick={onClick}
                  startIcon={icon}
                />
              ) : (
                <DefaultButton
                  data-testid={dataTestId}
                  key={name}
                  label={showNameOnDesktop ? name : undefined}
                  onClick={onClick}
                  startIcon={icon}
                  variant={variant === 'primary' ? 'contained' : 'outlined'}
                />
              ),
          )}
        </Stack>
      ) : (
        items.map(({ name, icon, onClick }) => (
          <SpeedDialAction
            icon={icon}
            key={name}
            onClick={onClick}
            open={isSpeedDialOpen}
            tooltipTitle={name}
          />
        ))
      ),
    [isLgUp, items, isSpeedDialOpen],
  );

  if (!portalEl) return undefined;

  return createPortal(children, portalEl);
};
