import { StyledComponent } from '@emotion/styled';
import { Card, CardProps } from '@mui/material';
import React from 'react';
import {
  ColorGreen600,
  ColorRed600,
  ColorYellow600,
} from '../../design-system/colors';
import { numberFormatterWithUnits } from '../../utils/number-formatter';
import { styled } from '../../utils/styled';
import { Box } from '../Box';
import { Divider } from '../Divider';
import { ArrowUpwardIcon } from '../Icons/ArrowUpwardIcon';
import { ThumbsDownIcon } from '../Icons/ThumbsDownIcon';
import { ThumbsUpIcon } from '../Icons/ThumbsUpIcon';
import { WatchLaterIcon } from '../Icons/WatchLaterIcon';
import { Stack } from '../Stack';
import { Typography, TypographyProps } from '../Typography';

export const StyledCard: StyledComponent<CardProps> = styled(Card)`
  padding: ${({ theme }) => theme.spacing(1)};
  align-items: flex-start;
  width: 552px;
  height: 495px;
`;
export const StyledTypography: StyledComponent<TypographyProps> = styled(
  Typography,
)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

function TotalClaims({ totalClaims }: { readonly totalClaims: number }) {
  return (
    <Stack alignItems="center" direction="column" flex={1}>
      <Typography variant="h3">
        {totalClaims === 0 ? '--' : `${numberFormatterWithUnits(totalClaims)}`}
      </Typography>
      <StyledTypography variant="caption">Total Claims</StyledTypography>
    </Stack>
  );
}

function VsAverageClaims({ VSaverage }: { readonly VSaverage: number }) {
  return (
    <Stack alignItems="center" direction="column" flex={1}>
      <Typography variant="h3">
        {VSaverage === 0 ? (
          '--'
        ) : (
          <>
            <ArrowUpwardIcon color={ColorRed600} />
            {numberFormatterWithUnits(VSaverage)}%
          </>
        )}
      </Typography>
      <StyledTypography variant="caption">VS. Average</StyledTypography>
    </Stack>
  );
}

export const iconsList = [
  <ThumbsUpIcon color={ColorGreen600} key="thumbs-up" />,
  <WatchLaterIcon color={ColorYellow600} key="watch-later" />,
  <ThumbsDownIcon color={ColorRed600} key="thumbs-down" />,
];

export interface SpikeContentProps {
  readonly spikeId: string;
  readonly totalClaims: number;
  readonly VSaverage: number;
  readonly claimData?: { key: string; value: string }[];
}

export function SpikeContent({
  spikeId,
  totalClaims,
  VSaverage,
  claimData,
}: SpikeContentProps) {
  return (
    <Box mb={2}>
      <Stack direction="row" mb={2} width="100%">
        <TotalClaims totalClaims={totalClaims} />
        <VsAverageClaims VSaverage={VSaverage} />
      </Stack>
      <Stack
        direction="row"
        divider={<Divider flexItem light orientation="vertical" />}
        justifyContent="center"
        spacing={2}
        width="100%"
      >
        {claimData?.map((item, index: number) => (
          <Stack
            data-testid="claim-data"
            direction="column"
            flex={0}
            key={`${spikeId}-claim-data`}
            sx={{ alignItems: 'center' }}
          >
            <Stack alignItems="center" direction="row">
              {iconsList[index]}
              <Typography sx={{ ml: 0 }} variant="subtitle">
                {item.value}
              </Typography>
            </Stack>
            <StyledTypography variant="caption">{item.key}</StyledTypography>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
