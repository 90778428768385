import { MenuItem, TextField as TextFieldMui } from '@mui/material';
import { Kind } from '../../types/kind';
import { styled } from '../../utils/styled';

export const StyledMenuItem = styled(MenuItem)`
  padding: ${({ theme }) => theme.spacing(2)};
  text-transform: capitalize;
  &.Mui-selected {
    background-color: unset;
    font-weight: 700;
    color: ${({ theme }) => theme.palette.primary.main};
  }
  &.Mui-selected:hover {
    background-color: ${({ theme }) => theme.palette.neutral[100]};
  }
`;

export const StyledIconWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const StyledField = styled(TextFieldMui, {
  shouldForwardProp: (prop) => prop !== 'kind',
})<{ kind: Kind }>`
  color: ${({ theme, kind }) =>
    kind === 'dark'
      ? theme.palette.text.primaryInverted
      : theme.palette.text.primary};

  //Style for Borders
  border-radius: ${({ theme }) => theme.borderRadii.outer};
  border-width: 1px;

  :hover .MuiOutlinedInput-notchedOutline input:not(:active) {
    border-color: ${({ theme, kind }) =>
      kind === 'dark'
        ? theme.palette.neutral[800]
        : theme.palette.neutral[200]};
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme, kind }) =>
      kind === 'dark'
        ? theme.palette.neutral[800]
        : theme.palette.neutral[200]};
  }
  //Style for the arrow at the right
  :hover .MuiSelect-icon input:not(:active) {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  .MuiSelect-icon {
    color: ${({ theme }) => theme.palette.primary.main};
    right: ${({ theme }) => theme.spacing(2)};
    top: calc(50% - 0.7em);
  }
  .MuiSelect-icon.Mui-disabled {
    color: ${({ theme, kind }) =>
      kind === 'dark'
        ? theme.palette.neutral[600]
        : theme.palette.neutral[200]};
  }
  //Styles for the Icon at the left
  .MuiInputBase-adornedStart {
    color: ${({ theme, kind }) =>
      kind === 'dark'
        ? theme.palette.neutral[600]
        : theme.palette.neutral[400]};
  }
  .MuiInputBase-adornedStart.Mui-disabled {
    color: ${({ theme, kind }) =>
      kind === 'dark'
        ? theme.palette.neutral[600]
        : theme.palette.neutral[300]};
  }
  //Style for the Label on hover
  :hover .MuiInputLabel-root input:not(:active) {
    color: ${({ theme, kind }) =>
      kind === 'dark'
        ? theme.palette.text.primaryInverted
        : theme.palette.text.primary};
  }
  //Style for the Label
  .MuiInputLabel-root.Mui-disabled {
    color: ${({ theme, kind }) =>
      kind === 'dark'
        ? theme.palette.neutral[800]
        : theme.palette.neutral[300]};
  }
  //State hover for changing the background color
  :hover .MuiInputBase-root input:not(:active) {
    background-color: ${({ theme, kind }) =>
      kind === 'dark'
        ? theme.palette.neutral[900]
        : theme.palette.neutral[100]};
  }
  //State hover for changing the background color when disabled
  :hover .MuiInputBase-root.Mui-disabled input:not(:active) {
    background-color: ${({ theme, kind }) =>
      kind === 'dark'
        ? theme.palette.background.dark
        : theme.palette.neutral[100]};
  }
  //Value Color
  .MuiInputBase-input {
    color: ${({ theme, kind }) =>
      kind === 'dark'
        ? theme.palette.text.primaryInverted
        : theme.palette.text.primary};
  }
  //Value Color
  .MuiInputBase-root.Mui-disabled {
    -webkit-text-fill-color: ${({ theme, kind }) =>
      kind === 'dark'
        ? theme.palette.neutral[800]
        : theme.palette.neutral[300]};
  }

  //Value color
  .MuiInputBase-root-MuiOutlinedInput-root {
    color: ${({ theme, kind }) =>
      kind === 'dark'
        ? theme.palette.neutral[200]
        : theme.palette.neutral[300]};
  }
  //Override the border color of the Input when Disabled
  .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme, kind }) =>
      kind === 'dark'
        ? theme.palette.neutral[800]
        : theme.palette.neutral[200]};
  }
  //Override the border color of the Input on hover
  :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme, kind }) =>
      kind === 'dark'
        ? theme.palette.neutral[800]
        : theme.palette.neutral[100]};
  }
  :hover .MuiOutlinedInput-root {
    background-color: ${({ theme, kind }) =>
      kind === 'dark'
        ? theme.palette.neutral[800]
        : theme.palette.neutral[100]};
  }
  //Color for the value of the select without the Icon
  //Color for the value of the select without the Icon Disabled
  .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: ${({ theme, kind }) =>
      kind === 'dark'
        ? theme.palette.neutral[800]
        : theme.palette.neutral[300]};
  }
  //Color for the value of the select without the Icon Disabled
  .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: ${({ theme, kind }) =>
      kind === 'dark'
        ? theme.palette.neutral[800]
        : theme.palette.neutral[200]};
  }
  .MuiSelect-select {
    color: ${({ theme, kind }) =>
      kind === 'dark'
        ? theme.palette.text.primaryInverted
        : theme.palette.text.primary};
    margin-left: ${({ theme }) => theme.spacing(0)};
  }

  .MuiInputAdornment-root {
    width: 40px;
  }
`;
