import { CSSObject, Theme } from '@emotion/react';

export const drawerWidth = '240px';

export const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  borderRight: 'none',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  borderRight: 'none',
  overflowX: 'hidden',
  width: '72px',
  [theme.breakpoints.up('sm')]: {
    width: '72px',
  },
});
