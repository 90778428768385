import { styled } from '@mui/material';
import React, { ReactNode } from 'react';
import { Button } from '../Button';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

const StyledButton = styled(Button)`
  height: 150px;
  width: 260px;
  padding: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.neutral[200]};
`;
const stackStyles = { alignItems: 'flex-start', textAlign: 'left' };
interface DescriptiveButtonProps {
  readonly title: string;
  readonly description: string;
  readonly onClick: () => void;
  readonly icon: ReactNode;
}

export function DescriptiveButton({
  title,
  onClick,
  description,
  icon,
}: DescriptiveButtonProps) {
  return (
    <StyledButton onClick={onClick} variant="outlined">
      <Stack spacing={3}>
        {icon}
        <Stack spacing={1} sx={stackStyles}>
          <Typography color="text.primary" variant="subtitle">
            {title}
          </Typography>
          <Typography color="text.secondary" variant="body">
            {description}
          </Typography>
        </Stack>
      </Stack>
    </StyledButton>
  );
}
