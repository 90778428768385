import { Slider } from '@mui/material';
import { styled } from '../../utils/styled';

export const StyledSlider = styled(Slider)(({ theme }) => ({
  width: '95%',
  marginLeft: theme.spacing(1),
  '& .Mui-checked': {
    left: '2px',
  },
  '& .MuiSlider-thumb': {
    color: theme.palette.paper[200],
  },
  '& .MuiSlider-rail': {
    color: theme.palette.neutral[200],
  },
  '&:hover .MuiSlider-thumb': {
    color: theme.palette.primary.main,
  },
  '& .MuiSlider-valueLabel': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '8px',
  },
}));

export const BoxValues = styled('div')`
  display: flex;
  justify-content: space-between;
`;
export const BoxLabels = styled('div')`
  display: flex;
  justify-content: space-between;
`;
