import React, { forwardRef, Ref, useMemo } from 'react';
import { PageCardContainer } from '../PageCardContainer';
import { SpikeContent } from '../SpikeContent';
import { SpikeInfoBlock } from '../SpikeInfoBlock/SpikeInfoBlock';

export interface SpikeCardProps {
  readonly spikeId: string;
  readonly totalClaims: number;
  readonly VSaverage: number;
  readonly isCarousel?: boolean;
  readonly title: string;
  readonly borderColor: string;
  readonly claimData: { key: string; value: string }[];
  readonly blocks: { title: string; info: { value: string; link: string }[] }[];
}

export const SpikeCard = forwardRef(
  (
    {
      spikeId,
      totalClaims,
      VSaverage,
      claimData,
      title,
      blocks,
      borderColor,
      isCarousel,
    }: SpikeCardProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const pageCardStyles = useMemo(
      () =>
        isCarousel
          ? { width: 'calc(100vw - 48px - 8px)' }
          : { flex: 1, maxWidth: '33%' },
      [isCarousel],
    );

    return (
      <PageCardContainer
        borderColor={borderColor}
        ref={ref}
        sx={pageCardStyles}
        title={title}
      >
        <SpikeContent
          VSaverage={VSaverage}
          claimData={claimData}
          spikeId={spikeId}
          totalClaims={totalClaims}
        />
        {blocks.map(({ title: blockTitle, info }) => (
          <SpikeInfoBlock
            info={info}
            key={`${spikeId}-spike-info-blocks`}
            title={blockTitle}
          />
        ))}
      </PageCardContainer>
    );
  },
);
