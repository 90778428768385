import React from 'react';
import { ErrorPage } from '../ErrorPage/ErrorPage';

export function ErrorBoundaryPage() {
  return (
    <ErrorPage
      reload
      subtitle="There was an unexpected error, please refresh the page to return to ClaimScore."
      title="Oops, something went wrong."
    />
  );
}
