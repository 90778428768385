import {
  applyActionCode,
  AuthError,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getMultiFactorResolver,
  multiFactor,
  MultiFactorError,
  MultiFactorResolver,
  onAuthStateChanged,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  signInWithEmailAndPassword,
  signOut as signOutFirebase,
  User,
  verifyPasswordResetCode,
} from 'firebase/auth';
import FirebaseManager from './init';

export type { User, AuthError, MultiFactorError };

// Todo: remove container class and export everything as named export.
export class FirebaseAuth {
  private static auth = FirebaseManager.getAuth();

  private static recaptchaVerifier: RecaptchaVerifier;
  private static multiFactorResolver: MultiFactorResolver;

  public static subscribeToUser = (cb: (user: User | null) => void) =>
    onAuthStateChanged(this.auth, cb);

  public static signIn = (email: string, password: string) => {
    return signInWithEmailAndPassword(this.auth, email, password);
  };

  public static getMultiFactorResolver = (error: MultiFactorError) => {
    this.multiFactorResolver = getMultiFactorResolver(this.auth, error);
  };

  public static initRecaptcha = (elementId: string) => {
    this.recaptchaVerifier = new RecaptchaVerifier(this.auth, elementId, {
      size: 'invisible',
    });
  };

  public static resetRecaptcha = () => {
    this.recaptchaVerifier.clear();
  };

  public static enrollPhoneNumber = async (phone: string) => {
    if (!this.auth.currentUser)
      throw new Error(
        'User must be logged in first with email/pass before saving a phone number',
      );
    const multiFactorSession = await multiFactor(
      this.auth.currentUser,
    ).getSession();

    const phoneInfoOptions = {
      phoneNumber: phone,
      session: multiFactorSession,
    };

    const phoneAuthProvider = new PhoneAuthProvider(this.auth);

    // Send SMS verification code.
    return phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      this.recaptchaVerifier,
    );
  };

  public static send2FACode = () => {
    const phoneInfoOptions = {
      multiFactorHint: this.multiFactorResolver.hints[0],
      session: this.multiFactorResolver.session,
    };
    const phoneAuthProvider = new PhoneAuthProvider(this.auth);
    return phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      this.recaptchaVerifier,
    );
  };

  public static verify2FACode = (
    verificationId: string,
    verificationCode: string,
  ) => {
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    // Complete enrollment.
    return this.multiFactorResolver.resolveSignIn(multiFactorAssertion);
  };

  public static signUp = (email: string, password: string) => {
    return createUserWithEmailAndPassword(this.auth, email, password);
  };

  public static signOut = () => {
    return signOutFirebase(this.auth);
  };

  public static getAuthToken = () => {
    return this.auth.currentUser?.getIdToken();
  };

  public static getUserId = () => {
    return this.auth.currentUser?.uid as string;
  };

  public static isEmailVerified = async () => {
    await this.auth.currentUser?.reload();
    return Boolean(this.auth.currentUser?.emailVerified);
  };

  public static isClaimScoreApproved = async () => {
    if (!this.auth.currentUser) return false;
    const { claims } = await this.auth.currentUser.getIdTokenResult(true);
    return Boolean(claims.approved);
  };

  public static isSuperAdmin = async () => {
    if (!this.auth.currentUser) return false;
    const { claims } = await this.auth.currentUser.getIdTokenResult(true);
    return Boolean(claims.superAdmin);
  };

  public static verifyPasswordReset = (actionCode: string) => {
    return verifyPasswordResetCode(this.auth, actionCode);
  };

  public static resetPassword = (actionCode: string, password: string) => {
    return confirmPasswordReset(this.auth, actionCode, password);
  };

  public static verifyEmail = (actionCode: string) => {
    return applyActionCode(this.auth, actionCode);
  };
}
