import {
  SpeedDial as SpeedDialMui,
  SpeedDialProps as SpeedDialPropsMui,
  styled,
} from '@mui/material';
import React from 'react';

export type SpeedDialProps = SpeedDialPropsMui;

const StyledSpeedDial = styled(SpeedDialMui)`
  .MuiSpeedDial-actions {
    align-items: flex-end;
  }
  .MuiFab-root {
    align-self: flex-end;
  }
`;

export function SpeedDial({ ...rest }: SpeedDialProps) {
  return <StyledSpeedDial {...rest} />;
}
