import {
  styled,
  TextField,
  TextFieldProps as TextFieldPropsMui,
} from '@mui/material';
import React, { useState } from 'react';
import { CleanButton } from '../../utils/clean-button';
import { StyledMenuItem } from '../Field/Field.styles';
import { ChevronDownIcon } from '../Icons/ChevronDownIcon';
import { Typography } from '../Typography';

export type ChipSelectProps = TextFieldPropsMui & {
  readonly options: string[];
  readonly startAdornmentTitle?: string;
};

const StyledSelectChip = styled(TextField)(() => ({
  textTransform: 'capitalize',
  '& .MuiInputBase-root': {
    maxHeight: '40px',
    borderRadius: '100px',
    padding: 0,
  },
  '.MuiSelect-icon': {
    top: 'calc(50% - 0.7em)',
  },
}));

const startAdornmentStyles = {
  padding: 1,
  paddingLeft: 2,
};

export function ChipSelect({
  options,
  startAdornmentTitle,
  ...rest
}: ChipSelectProps) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <StyledSelectChip
      InputProps={{
        startAdornment: (
          <CleanButton
            onClick={() => setOpen((prev) => !prev)}
            sx={startAdornmentStyles}
          >
            <Typography variant="subtitle">{startAdornmentTitle}</Typography>
          </CleanButton>
        ),
      }}
      SelectProps={{
        onClose: handleClose,
        onOpen: handleOpen,
        open,
        IconComponent: ChevronDownIcon,
      }}
      select
      {...rest}
    >
      {options.map((option) => (
        <StyledMenuItem key={`menu-item-${option}`} value={option}>
          {option}
        </StyledMenuItem>
      ))}
    </StyledSelectChip>
  );
}
