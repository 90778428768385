import { useQuery } from 'react-query';
import { getUserPermissionsAllOrganizations } from 'services/ClaimscoreApiService/Permissions';
import type { UserPermissionsAllOrganizationsResponse } from 'services/ClaimscoreApiService/Permissions/types';

const QUERY_KEY = 'getUserPermissions';

export const useGetUserPermissions = () =>
  useQuery<UserPermissionsAllOrganizationsResponse, Error>([QUERY_KEY], () =>
    getUserPermissionsAllOrganizations(),
  );
