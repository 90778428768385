import {
  FormControlLabel,
  RadioProps as RadioPropsMui,
  Radio as RadioUi,
} from '@mui/material';
import React from 'react';
import { ColorNeutral300, ColorNeutral600 } from '../../design-system/colors';
import { styled } from '../../utils/styled';
import { Typography } from '../Typography';

const StyledRadioUi = styled(RadioUi)`
  &.Mui-checked:hover {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`;

export interface RadioProps
  extends Pick<RadioPropsMui, 'name' | 'checked' | 'onClick'> {
  readonly label: string;
  readonly disabled?: boolean;
  readonly value: string;
}
export function Radio({ disabled, value, label, ...props }: RadioProps) {
  return (
    <FormControlLabel
      aria-labelledby={`${label}-radio`}
      control={
        <StyledRadioUi
          {...props}
          id={`${label}-radio`}
          inputProps={{
            'aria-label': label,
          }}
        />
      }
      disabled={disabled}
      label={
        <Typography
          color={disabled ? ColorNeutral300 : ColorNeutral600}
          variant="body"
        >
          {label}
        </Typography>
      }
      value={value}
    />
  );
}
