import {
  CardHeader as CardHeaderMui,
  CardHeaderProps as CardHeaderPropsMui,
} from '@mui/material';
import React from 'react';

export type CardHeaderProps = Pick<CardHeaderPropsMui, 'action' | 'title'>;

export function CardHeader({ ...rest }: CardHeaderProps) {
  return <CardHeaderMui {...rest} />;
}
