export const ColorNeutral100 = '#f7f7f7';
export const ColorNeutral200 = '#ededed';
export const ColorNeutral300 = '#c1c1c1';
export const ColorNeutral400 = '#9c9c9c';
export const ColorNeutral500 = '#838383';
export const ColorNeutral600 = '#6a6a6a';
export const ColorNeutral700 = '#454545';
export const ColorNeutral800 = '#393939';
export const ColorNeutral900 = '#242424';
export const ColorNeutral1000 = '#0d0d0d';
export const ColorPaper200 = '#ffffff';
export const ColorPaper400 = '#fcfaf8';
export const ColorPaper600 = '#f2eee6';
export const ColorPaper800 = '#eae5db';
export const ColorGreen200 = '#d8e7e6';
export const ColorGreen400 = '#9ec3c1';
export const ColorGreen600 = '#3e8682';
export const ColorGreen800 = '#3c6c6a';
export const ColorBlue200 = '#d9e4f1';
export const ColorBlue400 = '#9fbbdb';
export const ColorBlue600 = '#3f77b8';
export const ColorBlue800 = '#396599';
export const ColorRed200 = '#f8dedc';
export const ColorRed400 = '#eeada7';
export const ColorRed600 = '#dd5c4f';
export const ColorRed800 = '#b64439';
export const ColorYellow200 = '#fcf7dc';
export const ColorYellow400 = '#f8eba6';
export const ColorYellow600 = '#f1d64e';
export const ColorYellow800 = '#B39100';
export const ColorOrange200 = '#fbedd9';
export const ColorOrange400 = '#f5d1a1';
export const ColorOrange600 = '#eca343';
export const ColorOrange800 = '#c0781a';
export const ColorViolet200 = '#ebd9f1';
export const ColorViolet400 = '#cc9fdb';
export const ColorViolet600 = '#993fb8';
export const ColorViolet800 = '#752791';
