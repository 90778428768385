import React, {
  createContext,
  PropsWithChildren,
  useMemo,
  useState,
} from 'react';

interface LayoutContextProps {
  isSpeedDialOpen: boolean;
  handleSpeedDialOpen: () => void;
  handleSpeedDialClose: () => void;
}

export const LayoutContext = createContext<LayoutContextProps>({
  isSpeedDialOpen: false,
  handleSpeedDialOpen: () => undefined,
  handleSpeedDialClose: () => undefined,
});

export function LayoutProvider({ children }: PropsWithChildren) {
  const [isSpeedDialOpen, setIsSpeedDialOpen] = useState(false);

  const handleSpeedDialOpen = () => {
    setIsSpeedDialOpen(true);
  };
  const handleSpeedDialClose = () => {
    setIsSpeedDialOpen(false);
  };

  const layoutProviderValues = useMemo(
    () => ({
      isSpeedDialOpen,
      handleSpeedDialOpen,
      handleSpeedDialClose,
    }),
    [isSpeedDialOpen],
  );

  return (
    <LayoutContext.Provider value={layoutProviderValues}>
      {children}
    </LayoutContext.Provider>
  );
}
