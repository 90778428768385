import { Accordion, AccordionSummary } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { Kind } from '../../types/kind';
import { styled } from '../../utils/styled';
import { Stack } from '../Stack';

export const ItemWrapper = styled(Stack)`
  width: 100%;
`;

export const NavItemWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'kind',
})<{ kind: Kind }>`
  white-space: nowrap;
  background-color: ${({ theme, kind }) =>
    kind === 'dark'
      ? theme.palette.background.dark
      : theme.palette.background.paper};
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacing(0)};
  border-radius: ${({ theme }) => theme.borderRadii.outer};
  color: ${({ theme, kind }) =>
    kind === 'dark'
      ? theme.palette.text.secondaryInverted
      : theme.palette.text.secondary};

  &:hover {
    background-color: ${({ theme, kind }) =>
      kind === 'dark' ? theme.palette.neutral[800] : theme.palette.paper[400]};
  }
  width: 85%;
`;

export const StyledAccordionSummary = styled(AccordionSummary, {
  shouldForwardProp: (prop) => prop !== 'kind',
})<{ kind: Kind }>`
  background-color: ${({ theme, kind }) =>
    kind === 'dark'
      ? theme.palette.background.dark
      : theme.palette.background.paper};
  margin-bottom: ${({ theme }) => theme.spacing(0)};
  padding: ${({ theme }) => theme.spacing(1)};
  border-radius: ${({ theme }) => theme.borderRadii.outer};
  color: ${({ theme, kind }) =>
    kind === 'dark'
      ? theme.palette.text.secondaryInverted
      : theme.palette.text.secondary};

  &:hover {
    background-color: ${({ theme, kind }) =>
      kind === 'dark' ? theme.palette.neutral[800] : theme.palette.paper[400]};
  }
`;

export const AccordionLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;

  &:first-of-type > div {
    border-top-left-radius: ${({ theme }) => theme.borderRadii.outer};
    border-top-right-radius: ${({ theme }) => theme.borderRadii.outer};
  }
  &:last-child > div {
    border-bottom-left-radius: ${({ theme }) => theme.borderRadii.outer};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadii.outer};
  }
`;

export const AccordionInnerItems = styled('div', {
  shouldForwardProp: (prop) => prop !== 'kind',
})<{ kind: Kind }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ theme, kind }) =>
    kind === 'dark' ? theme.palette.neutral[900] : theme.palette.paper[200]};
  color: ${({ theme, kind }) =>
    kind === 'dark'
      ? theme.palette.text.secondaryInverted
      : theme.palette.text.secondary};
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};

  &:hover {
    background-color: ${({ theme, kind }) =>
      kind === 'dark' ? theme.palette.neutral[800] : theme.palette.paper[400]};
  }
`;

export const StyledAccordion = styled(Accordion, {
  shouldForwardProp: (prop) => prop !== 'kind',
})<{ kind: Kind }>`
  box-shadow: none;
  width: 100%;
  background-color: transparent;

  color: ${({ theme, kind }) =>
    kind === 'dark'
      ? theme.palette.text.secondaryInverted
      : theme.palette.text.secondary};

  &::before {
    opacity: 0;
  }

  &:hover {
    border-radius: ${({ theme }) => theme.borderRadii.outer};
  }

  &.Mui-expanded {
    margin: 0;
  }

  & .MuiAccordionSummary-expandIconWrapper {
    color: ${({ theme, kind }) =>
      kind === 'dark'
        ? theme.palette.text.secondaryInverted
        : theme.palette.text.secondary};
  }
  & .MuiAccordionSummary-root,
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 40px;
  }
  & .MuiAccordionSummary-content,
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
  & .MuiAccordionDetails-root {
    padding: 0;
  }
`;
