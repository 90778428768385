import { Accordion, AccordionSummary } from '@mui/material';
import { styled } from '../../utils/styled';

export const StyledAccordion = styled(Accordion)`
  ::before {
    height: 0;
  }
  :first-of-type {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }
  :last-of-type {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  box-shadow: none;
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0px;
  }
  .MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: 0px;
  }
  margin: 0px;
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  .MuiAccordionSummary-expandIconWrapper {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
