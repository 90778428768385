import {
  Avatar as AvatarMui,
  AvatarProps as AvatarPropsMui,
} from '@mui/material';
import React from 'react';

export type AvatarProps = AvatarPropsMui;

export function Avatar({ ...rest }: AvatarProps) {
  return <AvatarMui {...rest} />;
}
