import React from 'react';
import { styled } from '../../utils/styled';
import { Box } from '../Box';
import { Divider } from '../Divider';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-left: ${({ theme }) => theme.spacing(1)};
  overflow: hidden;
`;
export const StyledStack = styled(Stack)`
  background-color: ${({ theme }) => theme.palette.text.primaryInverted};
`;

export interface SpikeInfoBlockProps {
  readonly title: string;
  readonly info: { value: string; link: string }[];
}

export function SpikeInfoBlock({ info, title }: SpikeInfoBlockProps) {
  return (
    <Box sx={{ mb: 2 }}>
      <Box sx={{ mb: 1 }}>
        <Typography variant="subtitle">{title}</Typography>
      </Box>
      <StyledStack direction="column" spacing={1} width="100%">
        {info.map((item, index: number) => (
          <Stack
            data-testid="spike-info-block"
            direction="row"
            divider={<Divider flexItem light orientation="vertical" />}
            flex={0}
            justifyContent="flex-start"
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}-spike-blocks`}
            spacing={1}
            sx={{ p: 0 }}
          >
            <StyledTypography sx={{ width: '48px' }} variant="body">
              {item.value}
            </StyledTypography>
            <StyledTypography>{item.link}</StyledTypography>
          </Stack>
        ))}
      </StyledStack>
    </Box>
  );
}
