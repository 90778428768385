import { TextFieldProps } from '@mui/material';
import React, { ChangeEvent, KeyboardEventHandler, useState } from 'react';
import { Kind } from '../../types/kind';
import { numberFormatter } from '../../utils/number-formatter';
import { WithRequired } from '../../utils/withRequired';
import { StyledField } from './Field.styles';

const formatInput = (raw: string) => {
  if (raw === '') return raw;
  if (raw.includes('.')) {
    const [intPart, decimalPart] = raw.split('.');
    return `${numberFormatter.format(Number(intPart))}.${decimalPart}`;
  }
  return numberFormatter.format(Number(raw));
};

type CurrencyFieldProps = WithRequired<TextFieldProps, 'onChange'> & {
  readonly value: string;
  readonly kind: Kind;
};

export function CurrencyField(props: CurrencyFieldProps) {
  const { onChange, value: inputValue } = props;
  const [rawInput, setRawInput] = useState<string>(
    inputValue.replace(/[,]/gu, ''),
  );

  const handleCurrencyChange: KeyboardEventHandler = (event) => {
    const { key } = event;
    let raw = rawInput || '';

    if (key === 'Backspace') {
      const { selectionStart, selectionEnd } = event.target as HTMLInputElement;
      if (
        selectionStart !== null &&
        selectionEnd !== null &&
        selectionStart !== selectionEnd
      ) {
        const updatedValue =
          inputValue.slice(0, selectionStart) + inputValue.slice(selectionEnd);
        raw = updatedValue.replace(/[,]/gu, '');
      } else {
        raw = raw.substring(0, raw.length - 1);
        if (raw[raw.length - 1] === '.') {
          raw = raw.substring(0, raw.length - 1);
        }
      }
    } else if (!raw.split('.')[1] || raw.split('.')[1].length < 2) raw += key;
    if (Number.isNaN(Number(raw))) return;
    const eventCopy = { ...event } as unknown as ChangeEvent<HTMLInputElement>;
    eventCopy.target.value = formatInput(raw);
    onChange(eventCopy);
    setRawInput(raw);
  };

  return (
    <StyledField
      {...props}
      onBlur={(event) => {
        const eventCopy = {
          ...event,
        } as unknown as ChangeEvent<HTMLInputElement>;
        eventCopy.target.value = formatInput(rawInput);
        onChange(eventCopy);
      }}
      onChange={() => null}
      onKeyDown={handleCurrencyChange}
    />
  );
}
