import {
  CardContent as CardContentMui,
  CardContentProps as CardContentPropsMui,
} from '@mui/material';
import React from 'react';

export type CardContentProps = CardContentPropsMui;

export function CardContent({ ...rest }: CardContentProps) {
  return <CardContentMui {...rest} />;
}
