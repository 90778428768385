import { Stack, StackProps, Typography } from '@mui/material';
import React from 'react';
import { Divider } from '../Divider';

const addFlexStyle = {
  display: 'flex',
};

const labelStyle = {
  ...addFlexStyle,
  margin: 0,
  width: '96px',
};

interface DrawerItemProps {
  readonly label: string;
  readonly item: React.ReactNode;
  readonly sx?: StackProps['sx'];
}

export function DrawerItem({
  label,
  item,
  sx,
}: DrawerItemProps): React.ReactElement {
  return (
    <>
      <Stack alignItems="center" direction="row" gap={3} sx={sx}>
        <Typography color="text.secondary" sx={labelStyle} variant="caption">
          {label}
        </Typography>
        <Typography color="text.secondary" sx={addFlexStyle} variant="body">
          {item}
        </Typography>
      </Stack>
      <Divider noMargin />
    </>
  );
}
