import React, { createContext, useMemo, useState } from 'react';
import { Toast, ToastType } from '../Toast/Toast';

export const ToastContext = createContext<{
  displayToaster: (message: string, type: ToastType) => void;
}>({
  displayToaster: () => null,
});

export function ToastProvider({ children }: React.PropsWithChildren) {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState<ToastType>('info');

  const displayToaster = (newMessage: string, newType: ToastType) => {
    setIsOpen(true);
    setMessage(newMessage);
    setType(newType);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const value = useMemo(() => ({ displayToaster }), []);

  return (
    <ToastContext.Provider value={value}>
      <Toast
        message={message}
        onClose={handleClose}
        open={isOpen}
        type={type}
      />
      {children}
    </ToastContext.Provider>
  );
}
