import React, { ReactElement, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { styled, Typography } from 'ui';

const StyledTypography = styled(Typography)`
  text-transform: capitalize;
`;

export const PAGE_HEADER_PORTAL_ID = 'header-page-title';

export function PageHeaderPortal() {
  return <div id={PAGE_HEADER_PORTAL_ID} />;
}

interface PageHeaderTitleProps {
  title: ReactElement | string;
}
export const PageHeaderTitle = ({ title }: PageHeaderTitleProps) => {
  const [portalEl, setPortalEl] = useState<HTMLElement>();

  useLayoutEffect(() => {
    const foundPortal = document.getElementById(PAGE_HEADER_PORTAL_ID);
    if (!foundPortal)
      throw new Error('Portal container for PageHeaderTitle does not exist');
    setPortalEl(foundPortal);
  }, []);

  let childrenEl = title;
  if (typeof title === 'string') {
    childrenEl = <StyledTypography variant="h4">{title}</StyledTypography>;
  }

  if (!portalEl) return undefined;

  return createPortal(childrenEl, portalEl);
};
