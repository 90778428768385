import { TextField } from '@mui/material';
import React, { useRef, useState } from 'react';
import { styled } from '../../utils/styled';
import { Button } from '../Button';
import { SearchIcon } from '../Icons/SearchIcon';

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: theme.borderRadii.outer,
  marginLeft: 0,
  width: '100%',
}));

const StyledInput = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})<{ expanded: boolean }>`
  flex: 1;
  margin-left: ${({ theme }) => theme.spacing(1)};
  overflow: hidden;
  transition: width 300ms ease-in-out;
  width: ${({ expanded }) => (expanded ? '200px' : '0px')};
`;

interface AnimatedInputProps {
  readonly value: string;
  readonly onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}

export function AnimatedSearchInput({ value, onChange }: AnimatedInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [expanded, setExpanded] = useState(false);

  const handleIconClick = () => {
    setExpanded((prevState) => !prevState);
    if (!expanded && inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <StyledContainer>
      <Button
        onClick={handleIconClick}
        startIcon={<SearchIcon />}
        variant="text"
      />
      <StyledInput
        expanded={expanded}
        inputProps={{ 'aria-label': 'search' }}
        inputRef={inputRef}
        onChange={onChange}
        placeholder="Search..."
        value={value}
        variant="standard"
      />
    </StyledContainer>
  );
}
