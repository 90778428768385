import {
  ClaimTypes,
  Determination,
} from 'services/ClaimscoreApiService/shared/types';

export type FiltersForm = {
  startDate?: string;
  endDate?: string;
  determination?: Determination;
  deductionCode?: string;
  unitRange?: [number, number];
  payoutRange?: [number, number];
  scoreRange?: [number, number];
  claimTypes?: ClaimTypes;
};

export enum ExportType {
  Insight = 'insight',
  Raw = 'raw',
}

export type ExportsForm = {
  title: string;
};

export enum DrawerType {
  'Filter',
  'Export',
  'Timeframe',
}

export const clearFormValues: FiltersForm = {
  determination: undefined,
  deductionCode: undefined,
  unitRange: undefined,
  payoutRange: undefined,
  scoreRange: undefined,
  claimTypes: undefined,
};

export enum PermissionsType {
  Organization = 'organization',
  Case = 'case',
}

export enum AccountStatus {
  Active = 'active',
  Pending = 'pending_2fa',
  Denied = 'denied',
}
