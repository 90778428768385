import { useQuery } from 'react-query';
import { getCaseById } from 'services/ClaimscoreApiService/Cases';
import { Case } from 'services/ClaimscoreApiService/Cases/types';

const QUERY_KEY = 'getCaseById';

export const useGetCaseById = (caseId: string) =>
  useQuery<Case, Error>([QUERY_KEY, caseId], () => getCaseById(caseId), {
    enabled: Boolean(caseId),
  });
