import { StepLabel, styled } from '@mui/material';

export const StyledStepLabel = styled(StepLabel)`
  &.Mui-disabled.MuiStepLabel-alternativeLabel {
    svg {
      color: ${({ theme }) => theme.palette.background.paper};
      > circle {
        fill: ${({ theme }) => theme.palette.background.paper};
        stroke: ${({ theme }) => theme.palette.primary.main};
        r: 11px;
      }
      > text {
        fill: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }
`;
