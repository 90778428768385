import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useGetCaseById } from '@hooks/react-query/queries/useGetCaseById';
import { useGetUserPermissions } from '@hooks/react-query/queries/useGetUserPermissions';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ClaimScoreLoader } from 'ui';
import { Page, useAppLocation } from '@hooks/customHooks/useAppLocation';
import { decodeSearchParams } from '@pages/CaseDashboard/utils';
import { AccountOrganization } from 'services/ClaimscoreApiService/Accounts/types';
import { Case } from 'services/ClaimscoreApiService/Cases/types';
import { CasesPermissions } from 'services/ClaimscoreApiService/Permissions/types';
import { UserRole } from 'services/ClaimscoreApiService/shared/types';

export type UserOrgCaseFilters = {
  accountRole?: string;
  accountStatus?: string;
  approvalStatus?: string;
  invitationRole?: string;
  orgId?: string;
  caseId?: string;
};
export interface UserOrgCaseContextProps {
  userOrganizations: AccountOrganization[];
  userCases: CasesPermissions[];
  organizationSelected: AccountOrganization;
  casePermissionsSelected: CasesPermissions;
  isAdminOrOwnerInAnyOrgOrCase: boolean;
  getUserRoleOrg: () => UserRole | undefined;
  getUserRoleCase: () => UserRole | undefined;
  handleOrganizationSelect: (selectedOrganization: AccountOrganization) => void;
  handleCaseSelect: (selectedCase: CasesPermissions) => void;
  isLoading: boolean;
  filters: UserOrgCaseFilters;
  setFilters: (filters: UserOrgCaseFilters) => void;
  selectedCase: Case;
}

export const UserOrgCaseContext = createContext<UserOrgCaseContextProps>({
  userOrganizations: [] as AccountOrganization[],
  userCases: [] as CasesPermissions[],
  organizationSelected: {} as AccountOrganization,
  casePermissionsSelected: {} as CasesPermissions,
  isAdminOrOwnerInAnyOrgOrCase: false,
  handleOrganizationSelect: () => undefined,
  handleCaseSelect: () => undefined,
  isLoading: true,
  getUserRoleOrg: () => undefined,
  getUserRoleCase: () => undefined,
  filters: {} as UserOrgCaseFilters,
  setFilters: () => undefined,
  selectedCase: {} as Case,
});

export function UserOrgCaseProvider({ children }: PropsWithChildren) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<UserOrgCaseFilters>(
    decodeSearchParams(searchParams) as UserOrgCaseFilters,
  );
  const location = useLocation();
  const { page } = useAppLocation();

  const isPermissionsPage = page === Page.permissions;

  const [storedCase, setStoredCase] = useState<CasesPermissions>(
    {} as CasesPermissions,
  );

  const [storedOrganization, setStoredOrganization] =
    useState<AccountOrganization>({} as AccountOrganization);

  const { data: userData, isLoading: isLoadingOrg } = useGetUserPermissions();
  const { data: selectedCase, isLoading: isLoadingCase } = useGetCaseById(
    searchParams.get('caseId') || storedCase?.caseID || '',
  );

  const userOrganizations = useMemo(() => {
    let organizations =
      userData?.map(({ organizationPermission }) => organizationPermission) ||
      [];
    if (isPermissionsPage) {
      organizations = organizations.filter(
        (org) =>
          org.roleName === UserRole.Admin || org.roleName === UserRole.Owner,
      );
    }
    return organizations;
  }, [isPermissionsPage, userData]);

  const userCases = useMemo(() => {
    let cases =
      userData?.find(
        ({ organizationPermission }) =>
          organizationPermission.organizationID ===
          storedOrganization?.organizationID,
      )?.casesPermissions || [];
    if (isPermissionsPage) {
      cases = cases.filter(
        (currentCase) =>
          currentCase.roleName === UserRole.Admin ||
          currentCase.roleName === UserRole.Owner,
      );
    }
    return cases;
  }, [userData, isPermissionsPage, storedOrganization?.organizationID]);

  const handleCaseSelect = useCallback(
    (newSelectedCase: CasesPermissions) => {
      setStoredCase(newSelectedCase);

      setSearchParams(
        (prev) => {
          prev.set('caseId', newSelectedCase.caseID);
          return prev;
        },
        { state: location.state },
      );
    },
    [location.state, setSearchParams],
  );

  const handleOrganizationSelect = useCallback(
    (selectedOrganization: AccountOrganization) => {
      const caseId = searchParams.get('caseId');
      const selectedOrganizationId = selectedOrganization?.organizationID;

      setStoredOrganization(selectedOrganization);

      setSearchParams(
        (prev) => {
          prev.set('orgId', selectedOrganizationId);
          return prev;
        },
        { state: location.state },
      );

      const cases =
        userData?.find(
          ({ organizationPermission }) =>
            organizationPermission.organizationID ===
            selectedOrganization?.organizationID,
        )?.casesPermissions || [];

      if (caseId === selectedCase?.caseID) {
        handleCaseSelect(cases[0]);
        return;
      }

      const allowedCase = cases?.find(({ caseID }) => caseID === caseId);

      if (!allowedCase) {
        if (cases?.length) handleCaseSelect(cases[0]);
      }
    },
    [
      handleCaseSelect,
      location.state,
      searchParams,
      selectedCase?.caseID,
      setSearchParams,
      userData,
    ],
  );

  const getUserRoleOrg = useCallback(
    () => storedOrganization?.roleName as UserRole,
    [storedOrganization],
  );
  const getUserRoleCase = useCallback(
    () => storedCase?.roleName as UserRole,
    [storedCase],
  );

  const isAdminOrOwnerInAnyOrgOrCase = useMemo(() => {
    const isOwnerOrAdminInAnyOrg = userOrganizations.some(
      (organization) =>
        organization.roleName === UserRole.Admin ||
        organization.roleName === UserRole.Owner,
    );
    const isOwnerOrAdminInAnyCase = userCases.some(
      (currentCase) =>
        currentCase.roleName === UserRole.Admin ||
        currentCase.roleName === UserRole.Owner,
    );
    return isOwnerOrAdminInAnyOrg || isOwnerOrAdminInAnyCase;
  }, [userCases, userOrganizations]);

  useEffect(() => {
    if (!userOrganizations.length && !userCases.length) return;

    const orgId =
      searchParams.get('orgId') || storedOrganization?.organizationID;
    const caseId = searchParams.get('caseId') || storedCase?.caseID;

    const allowedOrg = userOrganizations?.find(
      ({ organizationID }) => organizationID === orgId,
    );

    const allowedCase = userCases?.find(({ caseID }) => caseID === caseId);

    if (allowedOrg) handleOrganizationSelect(allowedOrg);

    if (allowedCase) handleCaseSelect(allowedCase);

    if (!orgId) {
      handleOrganizationSelect(userOrganizations[0]);
    }
  }, [
    handleCaseSelect,
    handleOrganizationSelect,
    searchParams,
    storedCase?.caseID,
    storedOrganization?.organizationID,
    userCases,
    userOrganizations,
  ]);

  const UserOrgCaseProviderValues = useMemo(
    () => ({
      userOrganizations,
      userCases,
      organizationSelected: storedOrganization,
      casePermissionsSelected: storedCase,
      isAdminOrOwnerInAnyOrgOrCase,
      handleOrganizationSelect,
      handleCaseSelect,
      getUserRoleOrg,
      getUserRoleCase,
      isLoading: isLoadingOrg || isLoadingCase,
      filters,
      setFilters,
      selectedCase: selectedCase as Case,
    }),
    [
      userOrganizations,
      userCases,
      storedOrganization,
      storedCase,
      selectedCase,
      isAdminOrOwnerInAnyOrgOrCase,
      handleOrganizationSelect,
      handleCaseSelect,
      getUserRoleOrg,
      getUserRoleCase,
      isLoadingOrg,
      isLoadingCase,
      filters,
    ],
  );

  if (isLoadingOrg) {
    return <ClaimScoreLoader />;
  }

  return (
    <UserOrgCaseContext.Provider value={UserOrgCaseProviderValues}>
      {children}
    </UserOrgCaseContext.Provider>
  );
}

export const useUserOrgCase = () => useContext(UserOrgCaseContext);
