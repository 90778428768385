import {
  ButtonGroup as ButtonGroupMui,
  ButtonGroupProps as ButtonGroupPropsMui,
  styled,
} from '@mui/material';
import React from 'react';

export type ButtonGroupProps = ButtonGroupPropsMui;

const StyledButtonGroup = styled(ButtonGroupMui)`
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    width: 100%;
  }
  > button:last-child {
    flex: 1;
  }
`;

export function ButtonGroup({ ...rest }: ButtonGroupProps) {
  return <StyledButtonGroup {...rest} />;
}
