import { CssBaseline, ThemeProvider as MUIThemeProvider } from '@mui/material';
import React from 'react';
import { theme } from '../../design-system/theme';

export function ThemeProvider({ children }: React.PropsWithChildren) {
  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline>{children}</CssBaseline>
    </MUIThemeProvider>
  );
}
